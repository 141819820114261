<template>
  <div class="container">
    <img
      src="@/assets/images/promotion_invalid.png"
      alt=""
      class="img"
      v-if="isStaffPromotion"
    />
    <img src="@/assets/images/empty.png" alt="" class="img" v-else />
    <div class="text">{{ msg }}</div>
  </div>
</template>

<script>
export default {
  name: "emptyPage",
  props: {
    msg: {
      type: String,
      default: "无内容",
    },
    isStaffPromotion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  .img {
    width: 1.76rem;
    height: 1.76rem;
  }
  .text {
    text-align: center;
    margin-top: 0.32rem;
    font-size: 0.32rem;
    font-weight: 400;
    color: #9999;
    line-height: 0.48rem;
  }
}
</style>
