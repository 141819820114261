<template>
  <div class="staff-list">
    <van-search
      v-model="search_value"
      placeholder="请输入搜索"
      class="fixed"
      @search="search"
      @clear="clearSearch"
      @input="inputSearch"
    />
    <div v-if="userListShow.length !== 0 || isRequest">
      <div class="list-container" v-loading="isRequest">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="handle_user_search"
        >
          <van-checkbox-group
            v-model="userListChosen"
            v-for="(item, index) in userListShow"
            :key="index"
            ref="userCheckboxGroup"
          >
            <div class="checkbox-item" @click.stop="toggle(index)">
              <div class="item-info">
                <img v-default-avatar :src="item.avatar" />
                <div class="item-name">
                    <open-data :id="item.user_id" :name="item.NAME" />
                </div>
              </div>
              <van-checkbox
                :name="item.user_id + '_' + item.NAME"
                ref="userCheckbox"
              ></van-checkbox>
            </div>
          </van-checkbox-group>
        </van-list>
      </div>
      <div class="footer-btn fixed">
        <van-checkbox v-model="choseAll" @click="toggleAll">全选</van-checkbox>
        <button class="confirm-btn" @click="chooseStaff">
          确定 ({{ userListChosen.length }})
        </button>
      </div>
    </div>
    <empty-page :msg="emptyMsg" v-else></empty-page>
  </div>
</template>
<script>
import { AVATAR_DEFAULT_IMG } from '@/constant';
import EmptyPage from '@/components/emptyPage.vue';
import OpenData from '@/components/openData';

export default {
  name: "addStaff",
  components: { EmptyPage, OpenData },
  data() {
    return {
        AVATAR_DEFAULT_IMG,
      search_value: "",
      userList: [],
      userListChosen: [],
      userListTotal: [],
      userListCur: [],
      userListSearch: [],
      userListSearchChose: 0,
      listPage: 0,
      loading: false,
      finished: false,
      lastTimestamp: 0,
      isRequest: true,
    };
  },
  computed: {
    userListShow() {
      return this.search_value ? this.userListSearch : this.userListCur;
    },
    emptyMsg() {
      return this.search_value ? "搜索无结果" : "暂无员工";
    },
    choseAll: {
      get() {
        let listChosen = this.userListChosen;
        let list = this.userList;
        if (
          this.search_value &&
          this.userListSearch.length === this.userListSearchChose &&
          this.userListSearchChose !== 0
        ) {
          return true;
        } else if (
          list.length !== 0 &&
          listChosen.sort().toString() === list.sort().toString()
        ) {
          return true;
        }
        return false;
      },
      set(val) {
        if (val) {
          if (this.search_value) {
            this.choseAllSearch();
          } else {
            this.userListChosen = this.userList;
          }
        } else {
          if (this.search_value) {
            this.userListSearchChose = 0;
            this.userListSearch.forEach((element) => {
              let listItem = `${element.user_id}_${element.NAME}`;
              let index = this.userListChosen.indexOf(listItem);
              if (index !== -1) {
                this.userListChosen.splice(index, 1);
              }
            });
          } else {
            this.userListChosen = [];
          }
        }
      },
    },
  },
  mounted() {
    this.userListChosen = this.$store.state.userList;
    this.userListTotal = this.$store.state.staff_array || [];
  },
  methods: {
    get_user_search() {
      this.isRequest = true;
      this.$ajax("user_search").then((res) => {
        this.loading = false;
        if (res.data && res.data.code === 0) {
          let list = res.data.data;
          this.userListTotal = list;
          this.handle_user_search();
        } else {
          this.$toast("网络错误");
        }
        this.isRequest = false;
      });
    },
    handleStaffList() {
      this.userListTotal.forEach((element) => {
        let listItem = `${element.user_id}_${element.NAME}`;
        if (this.userList.indexOf(listItem) == -1) {
          this.userList.push(listItem);
        }
      });
    },
    async handle_user_search() {
      this.loading = true;
      this.finished = false;
      if (this.userListTotal.length === 0) {
        await this.get_user_search();
      }
      await this.handleStaffList();
      this.userListCur.push(
        ...this.userListTotal.slice(40 * this.listPage, 40 * this.listPage + 40)
      );
      if (this.userListCur.length < this.userListTotal.length) {
        this.listPage++;
      } else {
        this.finished = true;
      }
      this.loading = false;
      this.isRequest = false;
    },
    search_staff() {
      let val = this.search_value;
      this.userListTotal.forEach((e) => {
        if (e.NAME.indexOf(val) !== -1) {
          let listItem = `${e.user_id}_${e.NAME}`;
          if (this.userListChosen.indexOf(listItem) !== -1) {
            this.userListSearchChose++;
          }
          this.userListSearch.push(e);
        }
      });
      this.isRequest = false;
    },
    clearSearch() {
      this.userListSearch = [];
      this.userListSearchChose = 0;
      if (!this.search_value) {
        this.finished = false;
      }
    },
    search() {
      this.clearSearch();
      this.isRequest = true;
      this.search_staff();
    },
    inputSearch() {
      this.isRequest = true;
      this.clearSearch();
      if (this.search_value) {
        let newTimestamp = new Date().getTime();
        this.lastTimestamp = newTimestamp;
        setTimeout(() => {
          if (this.lastTimestamp === newTimestamp && this.search_value) {
            this.search_staff();
          }
        }, 200);
      } else {
        this.isRequest = false;
      }
    },
    chooseStaff() {
      this.$store.commit("setUserList", this.userListChosen);
      this.$router.go(-1);
    },
    toggle(index) {
      this.$refs.userCheckbox[index].toggle();
      if (this.search_value) {
        if (this.$refs.userCheckbox[index].checked) {
          this.userListSearchChose--;
        } else {
          this.userListSearchChose++;
        }
      }
    },
    toggleAll() {
      if (this.choseAll) {
        if (this.search_value) {
          this.choseAllSearch();
        } else {
          this.userListChosen = this.userList;
        }
      } else {
        if (this.search_value) {
          this.userListSearchChose = 0;
          this.userListSearch.forEach((element) => {
            let listItem = `${element.user_id}_${element.NAME}`;
            let index = this.userListChosen.indexOf(listItem);
            if (index !== -1) {
              this.userListChosen.splice(index, 1);
            }
          });
        } else {
          this.userListChosen = [];
        }
      }
    },
    choseAllSearch() {
      this.userListSearch.forEach((element) => {
        let listItem = `${element.user_id}_${element.NAME}`;
        if (this.userListChosen.indexOf(listItem) == -1) {
          this.userListChosen.push(listItem);
        }
      });
      this.userListSearchChose = this.userListSearch.length;
    },
  },
};
</script>
<style lang="scss" scoped>
.staff-list {
  overflow: auto;
  height: 100%;
  .list-container {
    position: relative;
    background-color: #fff;
    padding: 1.12rem 0.32rem 1.12rem;
    min-height: 2rem;
  }
  .checkbox-item {
    height: 0.96rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .item-info {
    display: flex;
    align-items: center;
    img {
      width: 0.64rem;
      height: 0.64rem;
      margin-right: 0.16rem;
      border-radius: 50%;
    }
    .item-name {
      width: 4.8rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .footer-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 0.32rem;
    width: 100%;
    box-sizing: border-box;
    height: 1.12rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 0.16rem 0rem rgba(0, 0, 0, 0.08);
  }
  .confirm-btn {
    width: 3.28rem;
    height: 0.8rem;
    background: #1472ff;
    border-radius: 0.4rem;
    border: none;
    outline: none;
    font-size: 0.32rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.48rem;
  }
}
</style>
<style lang="scss">
.staff-list {
  .van-search {
    position: fixed;
    top: 0;
    z-index: 9999;
  }
}
</style>
